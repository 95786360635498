<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOAD INVENTORY</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-3">
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="particulars_id"
                dense
                outlined
                label="Particulars"
                :items="['Laptop','Cellphone']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="date"
                label="Date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="model"
                label="Model"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="3"
              cols="12"
            >
              <v-text-field
                v-model="quantity"
                label="Quantity"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"

        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_staff_details"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-form>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            ID
          </th>
          <th class="text-uppercase">
            Date
          </th>
          <th class="text-uppercase">
            Particulars
          </th>
          <th class="text-uppercase">
            Model
          </th>
          <th class="text-uppercase">
            Quantity
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in user_items"
          :key="item.id"
        >
          <td>
            {{ item.id }}
          </td>
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ item.particulars }}
          </td>
          <td>
            {{ item.model }}
          </td>
          <td>
            {{ item.quantity }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountSearchOutline,
    mdiAlertOutline,
    mdiCloudUploadOutline,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return {
        particulars_id: '',
        particulars_items: [],

        saving: false,
        alert: false,
        alert_message: '',

        model: '',
        quantity: '',

        user_items: [],

        row: null,
        isLoading: false,
        search: '',
        search_items: [],

        member_id: null,
        district_id: null,
        location: null,
        date: '',
      }
    },
    created() {
      this.reset()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id','branch_id']),
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['list_of_inventory_report', 'create_inventory_report']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.quantity = ''
        this.list_of_inventory_report()
          .then(response => {
            this.user_items = response.data
          })
      },
      save_staff_details() {
        this.saving = true
        this.alert = false
        if (this.branch_id===1){
          this.alert = true
          this.alert_message = 'Please Select Branch First'
          this.saving = false
        }else{
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('particulars', this.particulars_id);
            data.append('quantity', this.quantity);
            data.append('model', this.model.toUpperCase());
            data.append('date', this.date);
            this.create_inventory_report(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }

      }
    }
  }
</script>
